
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// @ is an alias to /src

@Component
export default class BoxEntriesHeaderTitle extends Vue {
  private name = 'BoxEntriesHeaderTitle';
  @Prop()
  private str!: string;
}
