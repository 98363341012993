
import { Component, Mixins } from 'vue-property-decorator';
import SinglepageContainer from '@/mixins/SinglepageContainer';
import BoxEntriesHeaderName from '@/components/box/BoxEntriesHeaderName/BoxEntriesHeaderName.vue';
import store from '@/store';
import VueRouter from 'vue-router';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

const breadcrumbPath: BreadcrumbPath[] = [
  {
    label: 'ホーム',
    url: '/',
  },
  {
    label: 'おすすめ情報',
    url: '/pickup/',
  },
];

@Component({
  components: {
    BoxEntriesHeaderName,
  },
})
export default class PickupContainer extends Mixins(SinglepageContainer) {
  private name = 'PickupContainer';
    private loaded = true;

  private async beforeRouteEnter(to: any, from: VueRouter, next: any) {
    store.commit(`single/resetData`);
    const result: any = await store.dispatch(`single/getEntry`, {
      category: to.meta.category,
      id: to.params.id,
    });
    if (typeof result.data.datas[0] !== 'undefined') {
      const newBreadCrumbPath: BreadcrumbPath[] = [...breadcrumbPath];
      newBreadCrumbPath.push({
        label: result.data.datas[0].title,
        url: to.fullPath,
      });
      store.commit('breadcrumb/setPath', newBreadCrumbPath);
    }
    next();
  }

  private async beforeRouteUpdate(to: any, from: VueRouter, next: any) {
    // store.commit(`single/resetData`);
    this.loaded = false;
    const result: any = await store.dispatch(`single/getEntry`, {
      category: to.meta.category,
      id: to.params.id,
    });
    if (typeof result.data.datas[0] !== 'undefined') {
      const newBreadCrumbPath: BreadcrumbPath[] = [...breadcrumbPath];
      newBreadCrumbPath.push({
        label: result.data.datas[0].title,
        url: to.fullPath,
      });
      store.commit('breadcrumb/setPath', newBreadCrumbPath);
    }
    this.loaded = true;
    next();
  }

  private mounted() {
    this.campaignAgreeBoxInit();
    this.gwcouponInit();
  }
}
